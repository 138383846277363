import { useState } from "react";
import { Servicos } from "./Servicos";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";

const Accordion = () => {
  const [clicked, setClicked] = useState(false);

  const toogle = (id) => {
    if (clicked === id) {
      return setClicked(null);
    }
    setClicked(id);
  };

  return (
    <IconContext.Provider value={{ color: "#1d31cb", size: "25px" }}>
      <div className="m-2 lg:mt-4 ">
        
        <div className="border border-slate-200 rounded-md px-2 bg-slate-300 shadow-md">
          <div className=" rounded-md  p-3 text-slate-900 font-bold text-center text-xl">
          BENEFÍCIOS INCLUSOS
        </div>
          {Servicos.map((item, id) => {
            return (
              <div key={id}>
                <div
                  className="text-xl border rounded-md cursor-pointer drop-shadow-sm flex justify-between  p-3 my-2 bg-white"
                  onClick={() => toogle(id)}
                  
                >
                  <span className="my-auto ">{item.icon}</span>
                  <h1 className="text-center">{item.title}</h1>
                  <span className="my-auto ">
                    {clicked === id ? <FiMinus /> : <FiPlus />}
                  </span>
                </div>
                {clicked === id ? (
                  <div className="border text-gray-700 rounded-md drop-shadow-sm mb-2 flex justify-between p-2 bg-white">
                    <p>{item.text}</p>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Accordion;

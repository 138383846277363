import React from "react";
import { useLocation } from "react-router-dom";
import { sendData } from "../helpers/sendMoskit";
import { useNavigate } from "react-router-dom";

export default function Form2() {
  const location = useLocation();
  const state = location.state;
  let motivo;
  const navigate = useNavigate();

  const form2 = (e) => {
    motivo = e.target.value;
    sendData(state.formValues, state.truck, state.mensalidade, motivo);
    scrollToTop();
    navigate("/obrigado");
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div className="pt-4 bg-slate-300 m-2 border-none rounded-md shadow-md ">
      {" "}
      <h1 className="text-xl text-center py-2 bg-full text-slate-800 font-bold uppercase ">
        O que você achou da proposta?
      </h1>
      <div className="p-4  space-y-3 ">
        <button
          className="group w-full flex items-center rounded-full border shadow-md border-green-700 bg-green-700 px-4 py-2 font-medium text-white group-hover:text-blue-900 group-active:text-green-600 transition-colors hover:bg-transparent hover:text-green-700 focus:outline-none focus:ring"
          onClick={form2}
          value="Quer saber mais"
        >
          <span className="m-2 flex-shrink-0 rounded-full border border-current bg-slate-200 hover:bg-green-700 p-2 text-green-900 group-active:text-green-600"></span>
          Quero saber mais!
        </button>
        <button
          className="group w-full flex items-center rounded-full border shadow-md border-green-700 bg-green-700 px-4 py-2 font-medium text-white group-hover:text-green-900 group-active:text-green-600 transition-colors hover:bg-transparent hover:text-green-700 focus:outline-none focus:ring"
          onClick={form2}
          value="Gostou da proposta"
        >
          <span className="m-2 flex-shrink-0 rounded-full border border-current bg-slate-200 hover:bg-green-700 p-2 text-green-900  group-active:text-green-600"></span>
          Gostei da proposta!!
        </button>
        <button
          className="group w-full flex items-center rounded-full border shadow-md border-green-700 bg-green-700 px-4 py-2 font-medium text-white group-hover:text-green-900 group-active:text-green-600 transition-colors hover:bg-transparent hover:text-green-700 focus:outline-none focus:ring"
          onClick={form2}
          value="Fora do orçamento"
        >
          <span className="m-2 flex-shrink-0 rounded-full border border-current bg-slate-200 hover:bg-green-700 p-2 text-green-900 group-active:text-green-600"></span>
          Fora do meu orçamento
        </button>

        {/* <button
            onClick={form2}
            value="Quer saber mais"
            className="flex justify-center py-4 my-2  border border-transparent shadow-sm text-sm font-medium text-center rounded-full text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 w-full"
          >
            QUERO SABER MAIS!
          </button>
          <button
            onClick={form2}
            value="Gostou da proposta"
            className="flex justify-center py-4 my-2  border border-transparent shadow-sm text-sm font-medium text-center rounded-full text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 w-full"
          >
            GOSTEI DA PROPOSTA!
          </button>
          <button
            onClick={form2}
            value="Fora do orçamento"
            className="flex justify-center py-4 my-2  border border-transparent shadow-sm text-sm font-medium text-center rounded-full text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 w-full"
          >
            FORA DO MEU ORÇAMENTO
          </button> */}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Proteauto from "../assets/Proteauto.png";
import { SlEarphonesAlt } from "react-icons/sl";
import Acco from "../components/Footer/Acco";
import ReactPixel from "react-facebook-pixel";
import FAQ from "../components/FAQ";
import Banner from "../assets/truckbanner.png";


import Treze from "../assets/13anos.png";

export default function Obrigado() {
  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
    ReactPixel.trackCustom("Lead"); // For tracking custom'Cotacao2'); // For tracking custom . More info about custom : https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-
  }, []);
  // const initialValues = {
  //   name: "",
  //   phone: "",
  // };

  // const [formIndicacao, setFormIndicacao] = useState(initialValues);
  // const [formErrors, setFormErrors] = useState([]);
  // const [isSubmit, setIsSubmit] = useState(false);

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   setFormIndicacao({ ...formIndicacao, [name]: value });
  // };
  // const validade = (values) => {
  //   const errors = {};
  //   if (!values.name) {
  //     errors.name = "* Campo necessário";
  //   }
  //   if (!values.phone) {
  //     errors.phone = "* Campo necessário";
  //   }
  //   return errors;
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormErrors(validade(formIndicacao));
  //   setIsSubmit(true);
  // };

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     // sendEmail(formValues, truck, mensalidade);
  //     // sendData(formValues, truck, mensalidade);
  //     // navigate("/proposta", { state: { formValues, truck, mensalidade } });
  //   }
  // });

  return (
    <div className=" bg-slate-200">
      <div className="w-full p-2 bg-slate-300">
        <img
          src={Proteauto}
          alt="Logo da Proteauto"
          className="w-2/3 mx-auto"
        />
      </div>
      {/* <Steps /> */}

      <div className="flex bg-blue-700 rounded-md p-2 m-4 md:mx-40 lg:mx-80 text-white text-center ">
        <div className="my-auto p-2">
          <SlEarphonesAlt className="text-3xl" />
        </div>
        <div className="text-sm lg:text-xl">
          <p>
          Em breve, uma consultora da nossa equipe entrará em contato com você para passar mais informações a respeito da proteção.
          </p>
        </div>

        {/* EM BREVE, ALGUMA DE NOSSAS CONSULTORAS ENTRARÁ EM CONTATO COM VOCÊ PARA
        PASSAR MAIS INFORMAÇÕES A RESPEITO DA PROTEÇÃO. */}
      </div>
      <div className="">
        <img src={Banner} alt="Banner" className="object-cover h-18" />
      </div>

      <div className="pt-4 m-4 md:mx-40 lg:mx-80">
        <h1 className="text-lg leading-snug	pb-2	">
          POR QUE SER UM ASSOCIADO PROTEAUTO?
        </h1>
        <div>
          A Proteauto está desde 2009 no mercado. Uma empresa que vem crescendo
          a cada ano que passa, hoje já somos mais de 20.000 associados em todo
          o Brasil, em média 600 novos associados aderindo nossa proteção por
          mês. A Proteauto entrega tudo aquilo que promete, por isso tem
          crescido diariamente.
        </div>
      </div>
      <div className="w-40 md:w-60 pb-4 mx-auto">
        <img src={Treze} alt="13 anos protegendo o caminhoneiro" />
      </div>
      <div className="bg-blue-800 p-4 text-white text-center">
        VEJA ALGUNS DEPOIMENTOS DE NOSSOS ASSOCIADOS
      </div>
      <div className="m-4 md:mx-40 lg:mx-80">
        <div>
          <iframe
            className="w-full rounded-md md:h-56"
            src="https://www.youtube.com/embed/_JBpKVcztBQ"
            title="YouTube video player"
            // frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            // allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="m-4 md:mx-40 lg:mx-80">
        <FAQ />
      </div>
      {/* <div className="m-4 md:mx-40 lg:mx-80 bg-white rounded ">
        <div className="flex justify-center align-middle p-4  text-lg text-center text-white bg-blue-900 rounded-t">
            <div className="my-auto pr-3"><BsFillPeopleFill  /></div>
            <h1>Indique um amigo</h1>
          </div>
        <form onSubmit={handleSubmit} className="p-4">
          
          <label className="block text-md font-medium text-dark">
            Nome do seu amigo :{" "}
          </label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Nome e sobrenome"
            onChange={handleInputChange}
            className="my-1 p-1 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
          />
          <p className="text-sm text-red-600">{formErrors.name}</p>

          <label className="block text-md font-medium text-dark">
            Telefone do seu amigo :{" "}
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="(00) 00000-0000"
            onChange={handleInputChange}
            className="my-1 p-1 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
          />
          <p className="text-sm text-red-600">{formErrors.phone}</p>
          <button
            className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
            type="submit"
          >
            INDICAR
          </button>
        </form>
      </div> */}
      <div className="my-auto py-2 m-4 md:mx-40 lg:mx-80">
        <a
          className="inline-flex justify-center p-8 py-2 px-10 md:px-20 border border-transparent shadow-sm text-sm lg:text-xl font-medium text-center rounded-full text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full"
          href="https://wa.me/5544991574068?text=Ol%C3%A1%2C+tenho+interesse+em+saber+um+pouco+mais%21"
        >
          Tenho interesse e quero saber mais!
        </a>
      </div>
      <div className="flex-col justify-end bg-slate-100  inset-x-0 bottom-0">
        <div className="text-sm text-center p-2">
          <p className="font-bold">&copy; 2023 Eficaz Representação</p>
          <p className="font-bold">Todos os direitos reservados</p>
          <p className="m-2 text-xs md:mx-40 lg:mx-80">
            A Eficaz Representação está no mercado desde 2008 providenciando
            apoio aos nossos clientes no momento em que é mais preciso.
          </p>
        </div>
        <Acco />
      </div>
    </div>
  );
}
